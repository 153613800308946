@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-international-phone-input-container {
  @apply !w-full;
}

.react-international-phone-country-selector-button {
  @apply !w-full !h-14 !border-none !bg-gray-100;
}

.react-international-phone-country-selector {
  @apply !w-2/12;
}

.react-international-phone-input {
  @apply !w-10/12 !px-4 !text-sm !outline-none lg:!text-base !flex-1 !border-none !py-4 !h-14;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}